/* Footer Container */
.footer {
  background-color: #000;
  background-image: url(../images/footer-net.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.footer-wrapper {
  max-width: 1350px;
  padding: 20px 25px 100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Footer Sections */
.footer-item{
  flex: 1 1 23%;
  padding-left: 20px;
}

/* Section Headings */
.footer-item p{
  font-weight: bold;
  font-size: 18px;
}

/* Links */
.footer-item a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin: 10px 0;
}

.footer-item a:hover{
  text-decoration: underline;
}

/* Social Icons */
.social-icons {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically in the middle */
}

.social-icons a {
  color: #fff;
  font-size: 24px;
  margin-right: 20px;
  text-decoration: none;
}

.social-icons a:last-child {
  margin-right: 0; /* Remove margin for the last icon */
}

.social-icons a:hover {
  text-decoration: none;
}

@media  (max-width: 414px) {
  .footer-wrapper {
    flex-direction: column;
  }
}