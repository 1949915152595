@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://use.typekit.net/uqp0rbt.css");

.App {
  font-family: "Montserrat", sans-serif;
  color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.card-skeleton {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(128, 128, 128, 0.5);
  position: relative;
  min-height: 375px;
  transition: .3s ease;
  padding: 20px;
  box-shadow: 0 1px 6px rgba(128, 128, 128, 0.11);
}
.card-content {
  margin-top: 20px;
}
.card-skeleton-footer {
  margin-top: auto;
}
/* .App > * {
    width: 100%; 
} */

/* responsive design */
@media (max-width: 768px) {

}
