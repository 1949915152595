
.badge {
    position: absolute;
    top: 0;
    left: -15px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: rgb(233, 163, 67);
    color: black;
    font-weight: 600;
    font-size: 12px;
    
}.product-item-container {
    position: relative;
    display: flex;
    flex-direction: row;
}

.product-image-cart {
    width: 40px;
    height: 40px;
}

.font-bold {
    font-weight: bold;
}

.place-self-center {
    align-self: center;
}

.cart-quantity-container {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  flex:0 1 15%;
  padding: .5rem;
  align-items: center;
}

.cart-quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 50%;
}

.price-container{
  
  display:flex;
  flex-direction: column;
  flex-basis: 20%;
  gap: 2rem;
   justify-content: space-between;
}

.text-item-name{
  font-weight: bold;
  font-size: 14px;
  flex: content;
}
.text-item-name .sale-tag {
    background-color: #AC2825;
    color: #FFF;
    font-weight: 500;
    margin-left: 5px;
    padding: 0 3px;
}

.text-total-price {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}
.text-total {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}
.text-quantity {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}
.text-price {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}
.text-quantity-value {
    text-align: center;
    font-size: 14px;
}
.text-price-value {
    text-align: center;
    font-size: 14px;
}
.text-total-price {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}
.text-total-price-value {
    text-align: center;
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .cart-quantity-container {
        flex-direction: row;
    }
}
