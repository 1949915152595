.sustainability {
  background-color: #FFFFFF;
  position: relative;
}

.sustainability-wrapper {
  display: flex;
  max-width: 1350px;
  justify-content: space-around;
  padding: 20px 25px 50px;
  margin: auto;
  text-align: center;
  flex-wrap: wrap; 
}

.sustainability-title {
  position: absolute;
  top: 0;
  color: #173050;
  z-index: 10; 
  font-size: 40px;
  font-family: 'pulpo-rust-100', serif;
}

.section {
  width: 255px; 
  height: 260px; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center; 
  word-wrap: break-word; 
  text-align: center; 
  margin-top: 30px;
}

.section img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.section p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 450;
}

/* "Learn More" style */
.shop-now, .learn-more {
  margin-top: 10px;
  background-color: #fff; 
  color: #173050;
  border: 1px solid #173050;
  padding: 10px 15px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.shop-now {
  background-color: #e9a343;
  border-color: #e9a343;
  color: #fff;
}
.shop-now:hover, .learn-more:hover {
  background-color: #173050;
  border: 1px solid #173050;
  color: #fff;
}

/* responsive design */
@media (max-width: 1023px) {
  .sustainability-title {
    font-size: 36px; 
  }
  .section {
    width: 230px;
  }
}

@media (max-width: 767px) {
 
  .sustainability-title {
    font-size: 32px; 
  }
  .section {
    width: auto;
  }
}
@media (max-width: 725px) {
  .sustainability {
    flex-direction: column;
    align-items: center;
  }
  .section {
    margin-top: 50px;
  }
 
}
