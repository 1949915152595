.left-side {
  padding: 2rem;
}

.cart-items-container {
  display: flex;
  gap: 0.5rem;
}
.cart-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.cart-product-list {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cart-quantity-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 450;
}

.cart-product-list .cart-quantity {
  width: 100%;
  flex-direction: row;
  flex: 0 1 auto;
}
.cart-quantity-button {
  background-color: #173050;
  color: #fff;
  font-weight: 450;
  font-size: .875rem;
  min-width: 1em;
  min-height: 1rem;
  line-height: 1rem;
  padding: .35rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #173050;
}
.cart-quantity-button:hover {
  background-color: #fff;
  color: #173050;

}

.cart-total-container {
  flex: 0 1 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 450;
  padding: .5rem;
}

.cart-item-delete {
  display: flex;
  align-items: center;
  padding: .5rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color:#173050;
  font-weight: 450;
}
.cart-item-delete span {
  display: none;
  color: rgba(172, 40, 37, 1);
}

.cart-item-delete:hover {
  background-color: rgba(172, 40, 37, .1);
  color: rgba(172, 40, 37, 1);
}

.order-total-container {
  text-align: right;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1.5rem 0;
}

@media only screen and (max-width: 767px) {
  .cart-product-list .product-item-container .product-image-cart {
    width: 75px;
    height: 75px;
  }
  .cart-total-container {
      flex-direction: row;
      align-items: center;
  }
  .cart-product-list .cart-quantity {
      flex-basis: 40%;
  }
  .cart-item-delete {
      margin-left: 0;
      justify-content: center;
      align-items: unset;
  }
  .cart-item-delete span {
    display: block;
  }
  .cart-item-delete svg {
    display: none;
  }
}
@media only screen and (max-width: 567px) {
  .cart-actions {
    flex-direction: column;
    flex-flow: column-reverse;
    gap: 1rem;
  }
}