.cart-product-list .product-item-container {
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;
}

.cart-product-list .product-item-container .product-image-cart {
    width: 100px;
    height: 100px;
}

.cart-product-list .product-item-container .cart-summary-container {
    justify-content: flex-start;
    font-weight: 600;
    align-items: flex-end;
    gap: .2rem;
}
.product-item-info {
    display: flex;
    flex: 1 1 30%;
}
@media only screen and (max-width: 767px) {
    .cart-product-list .product-item-container {
        flex-direction: column;
        padding: 1rem 0;
    }
}