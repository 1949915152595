.favorite-products {
  background-color: #FFFFFF;
}
.favorite-products .mySwiper {
  margin-top: 120px
}
.favorite-products .mySwiper .swiper-button-prev,
.favorite-products .mySwiper .swiper-button-next {
  transform: translateY(-120%);
}

.favorite-products-wrapper {
  position: relative;
  display: flex;
  max-width: 1350px;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px 25px 50px;
  margin: auto;
}

.favorite-products-title{
  position: absolute;
  top: 0;
  color: #173050;
  text-align: center;
}

.favorite-titel{
  font-size: 40px;
  margin-bottom: 0;
  font-family: 'pulpo-rust-100', serif;
}

.favorite-subtitle{
  font-size: 18px;
  font-weight: 450;
  margin: 10px 0;
}
.favorite-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border: 1px solid rgba(128, 128, 128, 0.16);
  border-radius: 100%;
  box-shadow: 0 1px 4px rgba(128, 128, 128, 0.11);
  overflow: hidden;
  transition: border-color .3s ease;
}
.favorite-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: .8rem;
  border-radius: 100%;
  background-position: center;
  background-size: auto 100%;
  background-clip: content-box;
  transition: background-size .3s ease;
}

.product {
  text-align: center;
  width: 230px;
  position: relative;
}

.product img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.product .product-info {
  padding: 10px 0 0;
  margin-top: 10px;
}

.product-info h3 {
  font-family: 'pulpo-rust-100', serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  color: #173050;
}


.shop-now-fav {
  /* visibility: hidden; */
  /* opacity: 0; */
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 85px; */
  /* height: 85px; */
  border-radius: 100%;
  border: none;
  padding: 10px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none; 
  /* line-height: 85px;
  background-color: rgba(23, 48, 80, .7);
  backdrop-filter: blur(5px);
  color: white;
  transform: translate(-50%, -50%) scale(1.2);
  transition: .3s ease ; */
}
/* .favorite-image-wrapper:hover .shop-now-fav {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  display: block;
  opacity: 1;
} */
.favorite-image-wrapper:hover {
  box-shadow: 0 2px 8px rgba(128, 128, 128, 0.2);
  border-color: #173050;
}

.favorite-image-wrapper:hover .favorite-image {
  background-size: auto 110%;
}



/* responsive design */
/* @media only screen and (max-width: 1023px) {
  .product {
      width: 200px;
  }
  
}

@media only screen and (max-width: 767px) {
  .favorite-products {
      justify-content: center;
  }

  .product {
      width: 100%;
      margin: 10px 0;
  }

  .shop-now-fav {
    bottom: 10px;
  }
} */
