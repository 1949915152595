.sidebar {
  width: 100vw;
  background-color: #FFF;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(128, 128, 128, 0.25);
}
.sidebar-wrapper {
  max-width: 1350px;
  padding: 25px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.back-to-shop-button {
  font-size: 1rem;
  line-height: 1rem;
  padding: .3rem;
  cursor: pointer;
}

.button-sidebar-view-checkout {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  border-radius: 0.25rem;

  /* @apply hover:text-slate-700; */
}

.sidebar.open {
  animation: slideIn 0.3s forwards ease;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.sidebar.close {
  display: none;
  box-shadow: none;
}


.sidebar-cart-header {
  max-width: 1350px;
  padding: 1rem 25px;
  margin: 0 auto;
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}
.sidebar-product-list {
  flex: 0 1 40%;
}

.sidebar-actions-container {
  flex: 0 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid #e5e5e5;
  padding: 1rem 1rem 1rem 2rem;
}

.sidebar-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}


.sidebar-cart-total-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.375rem;
  font-weight: 600;
}


.products-list-cart,
.products-list-check {
  text-decoration: none;
  text-align: center;
  padding: .5rem;
  background-color: #173050;
  font-size: .875rem;
  font-weight: 450;
  color: #FFF;
  border: 1px solid #173050;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1 1 auto;
}

.products-list-cart {
  background-color: #FFF;
  color: #173050;
}

.products-list-cart:hover {
  background-color: #173050;
  color: #FFF;
}
.products-list-check:hover {
  background-color: #FFF;
  color: #173050;
}

.sidebar-container {
  position: relative;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 999; /* Ensure the backdrop is above other content */
  backdrop-filter: blur(5px);
}

@media only screen and (max-width: 1280px) {
  .sidebar-actions {
    flex-direction: column;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 1024px) {
  .sidebar-cart-total-container {
    font-size: 1.25rem;
  }
  .sidebar-product-list {
    flex-grow: 1;
  }
  .sidebar-actions-container {
    flex-grow: 1;
  }
}
@media only screen and (max-width: 578px) {
  .sidebar-wrapper {
    flex-direction: column;
  }
  .sidebar-actions-container {
    border-left: none;
    padding: 1rem;
  }
}