.account-container {
    max-width: 1230px;
    padding: 0 25px;
    margin: 117px auto 50px;
    overflow: hidden;
}
.account-wrapper {
    min-height: 70vh;
    display: grid;
    grid-template-columns: minmax(200px,25%) auto;
    grid-gap: 2rem;
}
.account-sidebar {
    position: sticky;
    border-right: 1px solid #e5e5e5;
    transition: all .3s ease;
}
.account-main {
    transition: all .3s ease;
}

.account-sidebar-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
}

.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem
}


.account-sidebar-wrapper li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    color: #333;
    font-weight: 450;
    transition: all .3s ease;
    cursor: pointer;
    position: relative;
    line-height: 1.5rem;
}
.account-sidebar-wrapper li.active {
    background-color: rgba(23, 48, 80, .1);
    color: #173050;
    font-weight: 600;
}
.account-sidebar-wrapper li.active::after {
    content:'';
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: #173050;
    right: 0;
    top: 0;
}
.account-sidebar-wrapper li:hover {
    background-color: rgba(23, 48, 80, .1)
}
.account-sidebar-wrapper li span {
    margin-right: .5rem;
}
.account-header {
    margin: 30px 0 20px;
    overflow: hidden;
}
.account-header h2 {
    font-size: 40px;
    color: #173050;
    font-family: 'pulpo-rust-100', serif;
    font-weight: normal;
    margin: 0;
}
.account-main-wrapper {
    overflow: hidden;
}
.account-main-wrapper h2 {
    margin: 0;
    font-size: 1.5rem;
    padding: .84rem 0;
    color: #173050;
    display: flex;
    align-items: center;
}

.account-main-wrapper h2 a {
    /* float: right; */
    font-size: 1rem;
    cursor: pointer;
    font-weight: 450;
    margin-left: auto;
}
.account-main-wrapper h2 a:hover {
    color: #4c7ebc;

}

.r-arrow {
    height: 1rem;
    margin-left: auto;
}

.column-item {
    border-top: 1px solid #e5e5e5;
    padding: 1.5rem 0;
    display: grid;
    grid:
        'itemImage  itemAction'
        'itemInfo itemAction'
        / 1fr 20%;
    gap: 1rem;
}

.column-item-image {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    grid-area: itemImage;
}
.item-image {
    width: 50px;
    height: 50px;
    background-color:rgba(23, 48, 80, .1)
}

.column-item-info {
    grid-area: itemInfo;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.info-group {
    padding: 0 1rem 0 0;
    font-size: 0.875rem;
}
.info-group h4 {
    margin: 0 0 .5rem;
    font-weight: 600;
}
.column-item-action {
    grid-area: itemAction;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.customer-profile-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    

}
.customer-profile-header button{
    padding: .5rem 1rem;
    width: 100%;
    border: 1px solid #173050;
    background-color: #FFF;
    color: #173050;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all .3s ease;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;
    flex-basis: 10%;
}
.customer-profile-header button:hover {
    background-color: #173050;
    color: #FFF;
}

.column-item-action button {
    padding: .5rem 1rem;
    width: 100%;
    border: 1px solid #173050;
    background-color: #FFF;
    color: #173050;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all .3s ease;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;
}
.column-item-action button:hover {
    background-color: #173050;
    color: #FFF;
}

.column-item-action a {
    font-size: 0.875rem;
    text-decoration: none;
    color: #565656;
    font-weight: 450;
    padding: .5rem 0;
    text-align: center;
    transition: all .3s ease;
}
.column-item-action a:hover {
    color: #173050;
}
.column-group {
    border-top: 1px solid #e5e5e5;
    overflow: hidden;
}
.column-group-item {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
.column-group-item button {
    margin-left: auto;
    padding: .5rem 1rem;
    border: 1px solid #173050;
    background-color: #FFF;
    color: #173050;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all .3s ease;
    font-weight: 450;
    font-family: 'Montserrat', sans-serif;
}
.column-group-item button:hover {
    background-color: #173050;
    color: #FFF;

}
.column-group h3 {
    margin: 0 0 .5rem;
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 600;
}

.column-group-item input {
    height: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: #565656;
    width: 100%;
    min-width: 280px;
    padding: .375rem;
    font-size: .875rem;
    box-sizing: border-box;
}

.column-group-item-label {
    font-weight: 600;
    font-size: 1rem;
    margin-right: .5rem;
    min-width: 110px;
}

.column-group-table {
    margin: 0 auto;
    overflow: hidden;
}

.column-group-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    border: 1px solid #e5e5e5;
}
.column-group-table table tr td:first-child {
    font-weight: 500;
}

.column-group-table table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.column-group-table table td {
    width: 40%;
    padding: .5rem;
    border: 1px solid #e5e5e5;
    text-align: left;
}
.column-group-image {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}
.column-group-image .item-image {
    width: 80px;
    height: 80px;
    background-color:rgba(23, 48, 80, .1)
}

.column-group-table .column-item-action button {
    padding: 1rem;
    width: 50%;
    color: #FFF;
    background-color: #173050;
    font-weight: 500;
}
.column-group-table .column-item-action button:hover {
    background-color: #FFF;
    color: #173050;
}

.column-group .column-group-item {
    padding: 0 1rem;
    gap: 1.5rem;
}

.column-group .column-group-item .item-image {
    width: 80px;
    height: 80px;

}

.account-mobile-return {
    background: none;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 0;
    color: #173050;
    display: none;
}

@media screen and (max-width: 768px) {
    .column-group-item {
        flex-direction: column;
        align-items: flex-start;
    }
    .column-group .column-group-item {
        padding: 0;
        gap: 1rem;
    }
    .column-group-item input {
        min-width: auto;
    }
    .column-item-action button {
        padding: .25rem .5rem;
    }
    .account-wrapper {
        width: calc(200% + 25px);
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
    }
    .account-wrapper.hide .account-sidebar {
        transform: translateX(calc(-100% - 25px));
    }
    .account-wrapper.hide .account-main {
        transform: translateX(calc(-100% - 25px));
    }
    .account-sidebar {
        border-right: none;
    }
    .account-mobile-return {
        display: block;
    }
    .account-sidebar-wrapper li {
        border-bottom: 1px solid #e5e5e5;
    }
    .account-sidebar-wrapper li:last-child {
        border-bottom: none;
    }
    .account-header {
        margin: 0 0 20px;
    }
}
