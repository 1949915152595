.cd-banner {
    width: 100%;
    position: sticky;
    top: 87px;
    z-index: 100;
    transition: all .3s ease;
}

.cd-banner.sm {
    position: static;
}

.cd-banner.pre {
    background-image: linear-gradient(-225deg, #475bbc 0%, #1D8FE1 48%, #4c7ebc 100%);
}
.cd-banner.cur {
    background-image: linear-gradient(-225deg, #AC2825 0%, #fc6076 48%, #e9a343 100%);
}
.cd-banner.last {
    background-image: linear-gradient(-225deg, #25bcb9 0%, #8e89d4 56%, #d57899 100%);
}

.cd-banner.scrolled {
    top: 59px;
}

.cd-banner-wrapper {
    max-width: 1350px;
    padding: 5px 25px 5px;
    margin: 0 auto;
}
.cd-banner-wrapper.sm {
    padding: 10px 25px 10px;
    margin-bottom: 10px;
}

.cd-banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    gap: 2%;
}

.cd-banner-container.sm {
    flex-direction: column;
    gap: 10px;
}

.cd-banner-container.sm .cd-banner-content {
    align-items: center;
    text-align: center;
    font-size: .875rem;
}

.cd-banner-container.sm .cd-banner-title {
    font-size: 1rem;
}

.cd-banner-container.sm .cd-banner-function {
    font-size: .875rem;
}

.cd-banner-container.sm .cd-banner-ticker {
    gap: 5px;
}

.cd-banner-container.sm .cd-banner-ticker-section {
    font-size: 12px;
}

.cd-banner-container.sm .cd-banner-ticker-value {
    width: 35px;
    height: 25px;
    font-size: 1rem;
    line-height: 25px;
}

.cd-banner-content {
    flex: 1 1 55%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    gap: 2px;
}

.cd-banner-function {
    flex: 1 1 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.cd-banner-ticker {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
}

.cd-banner-ticker-section {
    flex: 0 1 15%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: .875rem;
    line-height: 1;
    gap: 5px;
}

.cd-banner-ticker-value {
    flex-shrink: 0;
    width: 45px;
    height: 32px;
    text-align: center;
    font-size: 1.25rem;
    line-height: 32px;
    font-weight: 700;
    background: #FFF;
    color: #173050;
    border-radius: 3px;
}

.cd-banner-title {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0;
}

.cd-banner-subtitle {
    font-size: .875rem;
    margin: 0;
    font-weight: 500
}

.cd-banner-discount {
    display: inline-block;
    font-weight: 700;
    color: #e9a343;
    transform: scale(1.3);
    margin: 0 10px;
}

.cd-banner-button {
    display: inline-block;
    background: #e9a343;
    color: #FFF;
    padding: 0 15px;
    font-weight: 500;
    font-size: .875rem;
    line-height: 32px;
    text-decoration: none;
    transition: all .3s ease;
}

.cd-banner.cur .cd-banner-button,
.cd-banner.last .cd-banner-button {
    background: #FFF;
    color: #173050
}

.cd-banner-button:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
}

@media only screen and (max-width: 1280px) {

    .cd-banner-wrapper {
        padding: 10px 25px 10px;
    }

    .cd-banner-container {
        flex-direction: column;
        gap: 5px;
    }

    .cd-banner-content {
        align-items: center;
        text-align: center;
        font-size: .875rem;
    }

    .cd-banner-title {
        font-size: 1rem;
    }

    .cd-banner-function {
        flex-direction: column;
        gap: 8px;
        font-size: .875rem;
    }
    
    .cd-banner-ticker {
        gap: 5px;
    }

    .cd-banner-ticker-value {
        width: 35px;
        height: 25px;
        font-size: 1rem;
        line-height: 25px;
    }
    .cd-banner-button {
        line-height: 27px;
    }

}

@media only screen and (max-width: 576px) {
    .cd-banner {
        top: 79px;
    }
}