.recommends {
    overflow: hidden;
}

.recommends-wrapper {
    max-width: 1350px;
    margin: 40px auto;
    padding: 0 25px;
}
.mySwiper {
    padding-bottom: 40px;
}
.mySwiper .swiper-pagination-bullet-active {
    background-color: #173050;
}
.mySwiper .swiper-button-prev, 
.mySwiper .swiper-button-next {
    background: rgba(23, 48, 80, .8);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    backdrop-filter: blur(5px);
    transform: translateY(-50%);
}
.mySwiper .swiper-button-prev:after,
.mySwiper .swiper-button-next:after {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.recommends-title {
    color: #173050;
    text-align: center;
    padding: 0 25px;
    font-size: 40px;
    font-family: "Pulpo-rust-100", serif;
    font-weight: normal;
}

.recommends-list {
    width: 250px;
    border: 1px solid rgba(128, 128, 128, 0.16);
    position: relative;
    height: 350px;
    transition: border-color .3s ease;
    padding: 20px;
    flex-shrink: 0;
    box-shadow: 0 1px 4px rgba(128, 128, 128, 0.11);
    white-space: normal;
    font-weight: 450;
}

.recommends-list:hover {
    box-shadow: 0 2px 8px rgba(128, 128, 128, 0.2);
    border-color: #173050;
}

.recommends-image-wrapper {
    height: 50%;
    position: relative;
    overflow: hidden;
}
.recommends-image-wrapper .sale-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 64px;
    background: url(../images/Sale_icon.svg) no-repeat center / cover;
}

.recommends-image-wrapper:hover .recommends-image {
    transform: scale(1.1);
}

.recommends-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    transition: .3s ease;
}

.recommends-list-title {
    display: block;
    min-height: 50px;
    max-height: 75px;
    font-size: 18px;
    font-family: "Pulpo-rust-100", serif;
    color: #173050;
    margin: 15px 0 0;
    text-decoration: none;
    transition: .3s ease;
}

.recommends-list-title:hover {
    color: #4c7ebc
}

.recommends-info p {
    font-size: 16px;
    margin: 15px 0 10px;
    min-height: 40px;
    max-height: 40px;
}

.recommends-wrapper::-webkit-scrollbar {
    display: none;
}

.react-multi-carousel-item {
    padding: 0 10px;
}

@media (max-width: 1023px) {
    .recommends-title {
        font-size: 36px;
    }

}

@media (max-width: 767px) {
    .recommends-title {
        font-size: 28px;
    }
}