.store-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1050px;
    margin: auto;
}

.store-list-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 15%;
    text-align: center;
    height: 407px;
    position: relative;
}

.store-list-content {
    max-width: 1350px;
    margin: 0 auto;
    padding: 137px 25px 40px;
    overflow: hidden;
}

.list-content-preface {
    max-width: 1050px;
    margin: auto;
}

.store-list-item {
    position: relative;
    flex: 1 1;
    display: flex;
    /* background-color: #FAFAF5; */
    border: 1px solid rgba(128, 128, 128, 0.5);
    box-shadow: 0 1px 6px rgba(128, 128, 128, 0.11);
    padding: 20px;
}
.store-list-item:hover {
    box-shadow: 0 2px 8px rgba(128, 128, 128, 0.2);
    border-color: #173050;
}

.store-photo {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
}

.store-list-item:hover .store-navi {
    background-color: #173050;
    color: #fff;
}

.store-navi {
    display: block;
    width: 150px;
    box-sizing: border-box;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    color: #173050;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #173050;
    padding: 10px 0;
    transition: .3s ease;
}

.list-content-preface .page-title {
    color: #173050;
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    font-family: 'pulpo-rust-100', serif;
}

.list-content-preface .page-des {
    color: #000;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    font-weight: 450;
}

.list-content-preface p.page-des:last-of-type {
    color: #173050;
    font-size: 24px;
    font-weight: 450;
    font-style: italic;
}

.store-list-item h3 {
    font-size: 24px;
    font-weight: normal;
    color: #173050;
    margin: 0 0 15px;
    line-height: .8;
    font-family: 'pulpo-rust-100', serif;
}

.store-list-item .store-open {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px;
}

.store-list-item .store-open span {
    text-wrap: nowrap;
    margin-right: 20px;
}

.store-list-item p {
    font-weight: 450;
    margin: 0 0 10px;
}

.store-list-item p:last-of-type {
    margin-bottom: 0;
}

.store-list-item p a {
    color: #173050;
    font-weight: 450;
    text-decoration: none;
}

.store-list-item p a:hover {
    text-decoration: underline;
    color: #4c7ebc;
}

@media (max-width: 1023px) {
    .store-list-banner {
        height: 330px;
        background-position-y: 10%;
    }
}

@media (max-width: 576px) {
    .store-list-banner {
        height: 287px;
        background-position-y: -100%;
    }

    .list-content-preface .page-title {
        font-size: 30px;
    }

    .list-content-preface .page-des {
        font-size: 12px;
    }

    .list-content-preface p.page-des:last-of-type {
        font-size: 18px;
    }

    .store-list-item {
        flex-direction: column;
    }

    .store-photo {
        width: 100%;
        height: auto;
        aspect-ratio: 4/3;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .store-navi {
        width: 100%;
        margin-bottom: 15px;
    }

    .store-list-item h3 {
        font-size: 20px;
    }
}

@media (max-width: 370px) {
    .store-list-item .store-open {
        flex-direction: column;
    }
}