.product-reviews {
  background-image: url(../images/testimonials_bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

.product-reviews .mySwiper {
  margin-top: 120px
}

.product-reviews .mySwiper .swiper-button-prev,
.product-reviews .mySwiper .swiper-button-next {
  transform: translateY(-120%);
}

.product-reviews-wrapper {
  position: relative;
  display: flex;
  max-width: 1350px;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px 25px 50px;
  margin: auto;
  z-index: 1;
}

.product-reviews-title {
  position: absolute;
  top: 0;
  color: #173050;
  text-align: center;
}

.favorite-titel {
  font-size: 40px;
  margin-bottom: 0;
  font-family: 'pulpo-rust-100', serif;
  padding: 0 25px;
}

.favorite-subtitle {
  font-size: 18px;
  font-weight: 450;
  margin: 10px 0;
}

.reviews {
  text-align: center;
  transition: .3s ease;
  position: relative;
  box-sizing: border-box;
  padding: 25px 25px 10px;
  background-color: rgba(255, 255, 255, .6);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, .1);
}

.reviews .review-info {
  padding: 10px 0 0;
  margin-top: 10px;
  font-weight: 450;
  line-height: 1.5;
  color: #333;
}

.reviewer-name {
  font-weight: 600;
  font-size: 1.125rem;
}

.review-info h3 {
  font-family: 'pulpo-rust-100', serif;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}



.rating-wrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #E9A343
}

@media only screen and (max-width: 767px) {
  .favorite-titel {
    font-size: 28px;
  }

}

/* responsive design */
/* @media only screen and (max-width: 1023px) {
  .product {
      width: 200px;
  }
  
}

@media only screen and (max-width: 767px) {
  .favorite-products {
      justify-content: center;
  }

  .product {
      width: 100%;
      margin: 10px 0;
  }

  .shop-now-fav {
    bottom: 10px;
  }
} */