.product-item-image {
  width: 100px;
  height: 100px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
}

.product-item-container {
  display: flex;
  padding: 1rem 2rem 1rem 1rem;
  flex-direction: row;
  transition:.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.product-summary {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.product-total-price {
  font-size: 1rem;
  font-weight: 600;
}

.cart-summary-container {
  display: flex;
  justify-content: space-between;
}

.product-item-name {
  font-size: 1.125rem;
  font-weight: 600;
  color: #173050;
  margin-bottom: .5rem;
}

.product-item-name .sale-tag {
  display: inline-block;
  margin-left: .5rem;
  padding: .1rem .3rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  background-color: #AC2825;
}

.product-details-column {
  margin-left: 1rem;
  padding: 0 .5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}
.product-details-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
}

.quantity-selector {
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: row;
  flex-grow: 0;
  justify-content: space-between;
  place-items: center;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #6b7280;
}
@media only screen and (max-width: 768px) {
  .sidebar-product-list .product-item-container {
    flex-direction: column !important;
    align-items: center;
  }
  .sidebar-product-list .product-details-column {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 578px) {
  .sidebar-product-list .product-item-container {
    flex-direction: row !important;
    align-items: unset;
    padding: 1rem;
  }
  .sidebar-product-list .product-details-column {
    margin-left: 1rem !important;
    padding: 0;
  }
  .product-item-image {
    width: 75px;
    height: 75px;
  }
}