.shipping {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1;
}

.order-summary {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 1rem;
  width: 33.333333%;
  background-color: #FAFAF5;
}

.order-summary-title {
  font-weight: 700;
}
.text-value {
  font-weight: 450;
}

.order-value {
  font-weight: 450;
}
