.price-list-title {
  font-size: 64px;
  margin: 1rem 0;
  text-align: center;
  font-weight: normal;
  color: #FFF;
  font-family: 'pulpo-rust-100', serif;
}

.price-list-storename {
  min-width: 260px;
  color: #FFF;
  margin: 30px 0 0;
  position: relative;
  cursor: pointer;
  outline: none;
}

.price-list-storename::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #FFF;
  transition: all .3s ease;
}

.price-list-storename.show::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(180deg);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #FFF;
}

.price-list-storename .dropdown-toggle {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #FFF;
  background: transparent;
  color: #FFF;
  font-size: 40px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px 36px 8px 24px;
  cursor: pointer;
  transition: all .3s ease;
}

.price-list-storename .dropdown-toggle:hover {
  background: rgba(255, 255, 255, 0.2);
}

.price-list-storename .dropdown-menu {
  display: none;
}

.price-list-storename.show .dropdown-menu {
  display: flex;
  width: 100%;
  padding: 1rem;
  font-size: 24px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  flex-direction: column;
  justify-content: flex-start;
  background: #173050;
  transform: translateY(72px) !important
}

.price-list-storename.show .dropdown-menu .dropdown-item {
  color: #FFF;
  padding: 8px 8px;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

.price-list-storename.show .dropdown-menu .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.price-list-span {
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, .5);
  z-index: 99;
  position: relative;
  box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
}

.price-list-span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.price-list-span-wrapper {
  max-width: 1350px;
  padding: 0 25px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.price-list-span-wrapper h3 {
  font-size: 32px;
  font-weight: normal;
  margin: 1rem 0;
  text-align: center
}

.price-list-span-wrapper h4 {
  font-size: 34px;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center
}

.price-list-menu {
  position: sticky;
  top: 59px;
  background: #fff;
  z-index: 50;
  box-shadow: 0 1px 5px rgba(33, 35, 38, 0.2);
}

.store-select {
  max-width: 1350px;
  display: flex;
  list-style: none;
  padding: 0 25px;
  margin: 0 auto;
  color: #173050;
  justify-content: space-around;
}

.store-select-item {
  position: relative;

}

.store-select-item.selected a {
  font-size: 20px;
  font-weight: bold;
  position: relative;
}

.store-select-item.selected::after {
  content: '';
  position: absolute;
  background-color: #173050;
  height: 3px;
  width: 100%;
  bottom: 0;
  border-radius: 1.5px;
  animation: fold .3s ease;
}

@keyframes fold {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.store-select-item a {
  display: block;
  font-size: 18px;
  font-weight: 450;
  padding: 12px 20px;
  cursor: pointer;
  transition: .3s ease;
  line-height: 24px;
}

.price-list-wrapper {
  max-width: 1350px;
  padding: 0 25px;
  margin: 30px auto 40px;
  overflow: hidden;
}

.fundraiser-wrapper {
  max-width: 1350px;
  padding: 0 25px;
  margin: 30px auto 40px;
  overflow: hidden;
}

.fundraiser-content {
  display: flex;
  margin: 50px 0 60px;
}

.fundraiser-content p {
  margin: 1rem 0;
}

.fundraiser-content-left,
.fundraiser-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 50%;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

.fundraiser-content h1 {
  font-family: 'pulpo-rust-100', serif;
  font-size: calc(1vw + 100px);
  color: #173050;
}

.fundraiser-content h3 {
  font-size: calc(1vw + 24px);
  font-weight: 700;
}

.fundraiser-content-right .btn {
  display: inline-block;
  padding: 14px 22px;
  background-color: #AC2825;
  color: #FFF;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.fundraiser-content-right .btn:hover {
  background-color: #173050;
}

.mySwiper {
  width: 100%;
}

.mySwiper .swiper-wrapper {
  align-items: center;
}

.mySwiper .logos img {
  display: block;
  width: 80%;
  height: 80%;
  object-fit: contain;

}

.testimonials {
  max-width: 960px;
  padding: 3rem;
}

.testimonials .words {
  font-size: 18px;
  font-weight: 500
}

.padded {
  padding: 0px calc(2vw + 22px)
}

.fundraiser-form {
  max-width: 750px;
  width: 90%;
  margin: auto;
}

.fundraiser-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

.fundraiser-form-group label {
  font-size: 18px;
  font-weight: 500;
  margin: 1rem 0;
}

.fundraiser-form-group input {
  width: 100%;
  padding: 12px 8px;
  box-sizing: border-box;
}

.fundraiser-form button {
  display: inline-block;
  margin: 2rem 0;
  padding: 12px 30px;
  background-color: #173050;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
  border: 1px solid #173050;
  text-decoration: none;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
}

.fundraiser-form button:hover {
  background-color: #FFF;
  color: #173050;
}

.store-price .store-price-class {
  min-height: 400px;
  padding: 30px 0 25px;
  display: flex;
  /* grid-template-columns: repeat(4, 1fr); */
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
}

.store-price .class-item {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.store-price .class-item .class-item-list {
  display: flex;
}

.store-price .class-item:first-of-type img {
  margin-bottom: 6px;
}

.store-price .class-item h3 {
  font-size: 32px;
  font-weight: normal;
  line-height: 32px;
  margin: 10px 25px;
  padding: 5px 0 5px;
  color: #173050;
  text-align: center;
  font-family: 'pulpo-rust-100', serif;
}

.store-price .class-item h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 25px 25px;
  padding: 5px 0 5px;
  color: #333;
  border-top: thick double #173050;
  border-bottom: thick double #173050;
  text-align: center;
}

.store-price .class-item ul {
  list-style: none;
  flex: 1 1;
  padding: 0 25px;
  margin: 0;
}

.store-price .class-item ul li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  margin: 0 0 10px;

}

.class-item-link {
  text-decoration: none;
  text-align: center;
  margin: 1rem 1.5rem;
  padding: .5rem;
  border: 1px solid #173050;
  color: #173050;
}

.class-item-value {
  color: #173050;
  font-weight: 600;
}

.store-price .class-item .price-uom {
  font-size: 12px;
  font-weight: 450;
}

.store-price .class-item:nth-of-type(even) ul,
.store-price .class-item:last-of-type ul {
  border-right: none;
}

.store-special {
  width: 100%;
}

.store-special h4 {
  font-size: 32px;
  font-family: 'pulpo-rust-100', serif;
  font-weight: normal;
  color: #173050;
  margin: 0 0 2rem;
  text-align: center;
}

.store-special .product {
  width: 150px;
}

.store-special .product .favorite-image-wrapper {
  border: none;
  transition: all .3s ease;
}

.store-special .product .favorite-image-wrapper:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.store-special .product .product-info h3 {
  font-size: 1.25rem;
}

.store-special .product .favorite-image-wrapper .favorite-image {
  padding: 0;
}

.store-map {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  overflow: hidden;
  margin-top: 50px;
}

.store-map-frame,
.store-map-info {
  flex: 1 1;
}

.store-map-frame {
  aspect-ratio: 16/10;
}

.store-map-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 450;
  color: #000;
}

.store-map-info p {
  margin: 15px 0;
}

.store-map-info h3 {
  font-size: 40px;
  font-family: "Pulpo-rust-100", serif;
  font-weight: normal;
  color: #173050;
  margin: 0 0 20px;
}

.nav-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: #173050;
  margin: auto 0 10px;
  color: #FFF;
  border: 1px solid #173050;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #FFF;
  color: #173050;
}

.tile-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 1rem 0;
  align-items: flex-start;
}

.tile-image {
  width: 240px;
  height: 240px;
  place-self: flex-start;
  object-fit: cover;
}

.tile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tile-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  padding: 0 2rem 0 0;
}

.tile-text p {
  margin: 0;
}

@media (max-width: 1023px) {
  .store-select {
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }

  .store-price .class-item {
    flex: 1 0 50%;
  }

  .nav-button {
    display: block;
    margin: 15px 0;
  }

  .price-list-title {
    font-size: 48px;
  }

  .price-list-span-wrapper h3 {
    font-size: 24px;
  }

  .price-list-span-wrapper h4 {
    font-size: 26px;
  }

  .fundraiser-content {
    flex-direction: column;
  }

  .fundraiser-content img {
    width: 80% !important;
  }

  .testimonials {
    width: 100%;
    padding: 3rem 0;
  }
}

@media (max-width: 768px) {
  .price-list-span {
    padding-top: 87px;
    height: 350px;
  }

  .store-price .class-item ul {
    padding: 0;
  }

  .store-price .class-item {
    flex: 1 0 100%;
  }

  .store-map {
    flex-direction: column;
  }

  .price-list-storename .dropdown-toggle {
    font-size: 28px;
  }

  .price-list-storename.show .dropdown-menu {
    transform: translateY(57px) !important;
  }

  .store-price .class-item h4 {
    margin: 0 0 25px;
  }
}

@media (max-width: 576px) {
  .store-map-frame {
    aspect-ratio: 5/4;
  }

  .price-list-span {
    padding-top: 79px;
    height: 250px;
  }

  .price-list-title {
    font-size: 28px;
  }

  .price-list-storename {
    font-size: 40px;
  }

  .store-price .class-item ul {
    padding: 0;
  }

  .store-price .class-item .class-item-list {
    flex-direction: column;
  }

  .store-special .product {
    width: 190px;
  }

  .tile-layout {
    grid-template-columns: repeat(1, 1fr);
  }

  .tile-image {
    place-self: center;
  }

  .tile-text {
    padding: 0;
  }
}