.tg-banner {
    background-color: #dea650;
    padding: 36px 33px 27px;
    min-height: 319px;
    position: relative;
}

.tg-banner::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 585px;
    background: url(../images/thanksgiving_banner.webp) no-repeat center / cover;
    z-index: 0;
}

.tg-banner-wrapper {
    position: relative;
    z-index: 1;
    border: 1px solid #FFF;
    min-height: 326px;
}

.tg-banner-content {
    width: calc(100% - 551px);
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    position: relative;
}

.tg-banner-content::before {
    content: "GRATITUDE AND FRESHNESS";
    display: block;
    position: absolute;
    color:#692D12;
    top: -16px;
    left: 50%;
    padding: 0 20px;
    transform: translateX(-50%);
    background: #dea650;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-wrap: nowrap;
}

.tg-banner-title {
    font-family: 'pulpo-rust-100', serif;
    font-size: 2.6rem;
    color: #EBF0E4;
    text-shadow: 2px 5px 0 #692D1220;
    margin: 1rem 0 .5rem;
}

.tg-banner-product-name {
    color: #692D12;
    font-size: 3rem;
    font-weight: 600;
    margin: .5rem 0;
}

.tg-banner-description {
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 450;
    line-height: 1;
    margin: 0;
}

.tg-banner-date {
    color: #FFF;
    font-weight: 450;
    line-height: 1;
    margin: .5rem 0 ;
    font-size: 3.5rem
}

.tg-banner-button {
    display: inline-block;
    color: #692D12;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem 1rem;
    margin: .5rem 0 0;
    border: 2px solid #692D12;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease;
}

.tg-banner-button:hover {
    background-color: #692D12;
    color: #FFF;
}

@media only screen and (max-width: 1590px) {
    .tg-banner-content::before {
        font-size: 1.2rem;
        top: -12px;
    }
    .tg-banner-title {
        font-size: 2.2rem;
    }
    .tg-banner-product-name {
        font-size: 2.6rem;
    }
    .tg-banner-date {
        font-size: 2.5rem;
    }
    .tg-banner-description {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 1024px) {
    .tg-banner-wrapper {
        background-color:rgba(222, 166, 80, 0.8);
        backdrop-filter: blur(15px);
    }
    .tg-banner-content::before {
        background-color: #FFF;
    }
    .tg-banner-content {
        width: 100%;
    }
    .tg-banner-product-name {
        text-shadow: 2px 5px 0 #692D1220
    }
    .tg-banner-date {
        font-size: 2.2rem;
    }
}

@media only screen and (max-width: 678px) {
    .tg-banner-content::before {
        font-size: 1rem;
        top: -10px;
        letter-spacing: 0;
    }
    .tg-banner-title {
        font-size: 1.8rem;
    }
    .tg-banner-product-name {
        font-size: 2.2rem;
    }
}