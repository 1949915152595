.product-detail-wrapper {
  display: flex;
  margin: 147px auto 50px;
  padding: 0 25px;
  max-width: 1350px;
  font-weight: 450;
}

.image-gallery {
  width: 55%;
}

.main-image-container {
  width: 100%;
  padding-top: 75%;
  position: relative;
}

.main-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0.3s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-image.out-of-stock {
  filter: grayscale(90%);
}

.image-thumbnails {
  display: flex;
  margin-top: 10px;
}

.thumbnail {
  width: 100px;
  height: 75px;
  object-fit: cover;
  margin-right: 5px;
  cursor: pointer;
  padding: 2px;
  border: 2px solid transparent;
}

.thumbnail.selected {
  border-color: #173050;
}

.product-info-detail {
  width: 45%;
  margin-left: 60px;
}

.product-info-detail h2 {
  margin: 0 0 30px;
  color: #173050;
  font-size: 36px;
  font-family: 'pulpo-rust-100', serif;
  font-weight: normal;
}
.product-info-detail span {
  display: inline-block;
  color: #333;
}
.product-info-detail span.price {
  font-size: 28px;
  letter-spacing: 1px;
}

.quantity {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.product-description {
  margin: 1.5rem auto;
  padding: 0 25px;
  max-width: 1350px;
  font-weight: 450;
}

.product-description h3 {
  color: #173050;
  font-family: 'pulpo-rust-100', serif;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(86, 86, 86, .2);
}

.quantity label {
  margin-right: 10px;
  color: #173050;
}

.quantity input {
  width: 60px;
  padding: 5px;
  text-align: center;
  border: 1px solid #173050;
  border-top: 0;
  border-right: 0;
  border-left: 0;

}

.add-to-cart, .buy-now {
  padding: 15px 20px;
  border: 1px solid #173050;
  cursor: pointer;
  width: 100%;
  color: #173050;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 30px;
  transition: all .3s ease;
}

.add-to-cart {
  background-color: #fff;

}

.add-to-cart.out-of-stock {
  background-color: #666;
  border-color: #666;
  color: #fff;
  cursor: not-allowed;
}
.add-to-cart.out-of-stock:hover {
  background-color: #666;
  border-color: #666;
  color: #fff;
}

.buy-now{
  display: block;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-color: #e9a343;
  border: 1px solid #e9a343;
  margin-top: 15px;
}


.add-to-cart:hover, .buy-now:hover {
  background-color: #173050;
  border-color: #173050;
  color: #FFF;
}


.social-media-links {
  margin-top: 20px;
  font-weight: 500;
  color: #173050;
}

.detail-social-icons{
  display: flex; 
  align-items: center;
}

.detail-social-icons a {
  color: #173050;
  font-size: 18px;
  margin-right: 10px;
  text-decoration: none;
}
.product-biography {
  margin: 1.5rem auto;
  font-size: 16px;
  color: #000;
  line-height: 1.5;
}

.sale-badge {
  color: #AC2825 !important;
  margin-left: 1.5rem;
  font-weight: 500;
  font-size: 1.5rem;
}

.main-image-container .sale-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 128px;
  height: 128px;
  background: url(../images/Sale_icon.svg) no-repeat center / cover;
}

.main-image-container .oneday-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 128px;
  height: 128px;
  background: url(../images/Oneday_icon.svg) no-repeat center / cover;
}
.main-image-container .instore-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 128px;
  height: 128px;
  background: url(../images/Instore_icon.svg) no-repeat center / cover;
}

.main-image-container .feature-label  {
  display: block;
  width: 128px;
  height: 128px;
  left: 0;
  top: 0;
  position: absolute;
  background: url(../images/Feature_icon.svg) no-repeat center / cover;
}

.available-stores-menu {
  width: 100%;
  font-family: 'pulpo-rust-100', serif;
  color: #173050;
  font-size: 1.25rem;
  border-radius: 0;
  border: 1px solid rgba(128, 128, 128, 0.25);
  padding: .5rem .25rem;
  outline: none;
  box-shadow: 0 1px 6px rgba(128, 128, 128, 0.11);
}

.available-stores p {
  margin: .5rem 0;
  color: #173050;
  font-size: 1rem;
}


/* responsive design */

@media only screen and (max-width: 1023px) {
  .product-info-detail {
    margin-left: 30px;
  }
  .thumbnail {
    width: 75px;
    height: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .product-detail-wrapper {
    flex-direction: column;
  }
  .product-info-detail {
    margin-top: 30px;
    margin-left: 0;
  }
  .image-gallery,
  .product-info-detail {
    width: auto;
  }
}
@media only screen and (max-width: 576px) {
  .thumbnail {
    width: 60px;
    height: 45px;
  }
}
