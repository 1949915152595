.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
.ourfish-wrapper {
    max-width: 1350px;
    padding: 0 25px;
    margin: 117px auto 70px;
    overflow: hidden;
}

.ourfish-wrapper h2 {
    font-size: 40px;
    margin: 1rem 0;
    text-align: center;
    font-weight: 400;
    line-height: 1.5;
    color: #173050;
    font-family: 'pulpo-rust-100', serif;
}

.ourfish-wrapper p {
    font-size: 1.125rem;
    margin: 1.125rem 0;
    font-weight: 450;
}

.ourfish-banner {
    height: 600px;
    background: url(../images/FMS_ourfish-01.jpeg) no-repeat center center / cover fixed;
}

.ourfish-banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1350px;
    height: 100%;
    padding: 0 25px;
    margin: auto;
}

.ourfish-banner-content {
    max-width: 480px;
    background-color: rgba(255, 255, 255, .85);
    padding: 36px;
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    font-size: 1.125rem;
    font-weight: 450;
    font-family: 'pulpo-rust-100', serif;
    color: #173050;
    line-height: 1.5;
}
.ourfish-section {
    margin: 0 0 100px;
}
.ourfish-section-header {
    display: flex;
    align-items: center;
}

.ourfish-section h2 {
    flex: 1 1 50%;
    width: 100%;
    color: #AC2825;
    text-align: left;
    position: relative;
}

.ourfish-section h2::after {
    content: attr(data-content);
    color: #333;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 450;
    position: absolute;
    left: 0;
    bottom: -1rem;
}

.ourfish-section h3 {
    margin-bottom: 5px;
    text-transform: uppercase;
}

.ourfish-section p {
    margin-top: 5px;
}

.image-container-left,
.image-container-right {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    margin-left: 50px;
    margin-bottom: 1rem;
    max-width: 40%;
    overflow: hidden;
    aspect-ratio: 10/9
}

.image-container-left {
    float: left;
    margin-right: 50px;
    margin-left: 0;
}

.image-container-right img,
.image-container-left img {
    width: 100%;
}

.ourfish-section ol {
    padding-left: 1.15rem;
    font-size: 1.125rem;
}

.ourfish-section ol p {
    font-weight: 400;
    font-size: 1.125rem;
}

.ourfish-section-title {
    color:#AC2825;
    font-weight: 600;
}

.ourfish-link {
    margin: 0 0 0 auto;
    font-size: 1rem;
    font-weight: 500;
    float: right;
    color: #fff;
    background-color: #e9a343;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    text-decoration: none;
    transition: all .3s ease;
    text-align: center;
    box-sizing: border-box;
}

.ourfish-link:hover {
    background-color: #173050;
}

@media only screen and (max-width: 1024px) {
    .ourfish-section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    .ourfish-link {
        margin: 0;
    }
    .ourfish-banner {
        background-attachment: scroll;
    }

}

@media only screen and (max-width: 768px) {
    .ourfish-wrapper h2 {
        font-size: 24px;
    }
    .ourfish-wrapper p {
        font-size: .875rem;
    }
    .ourfish-banner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ourfish-banner-content {
        padding: 28px;
        text-align: center;
        font-size: 1rem;
    }

    .image-container-right {
        float: none;
        margin-left: 0;
        margin-right: 0;
        max-width: 100% !important;
    }

    .image-container-left {
        float: none;
        margin-right: 0;
        margin-left: 0;
        max-width: 100%;
    }
    .ourfish-section h3 {
        font-size: 1rem;
    }
    .ourfish-section {
        margin: 0 0 60px;
    }
    .ourfish-section ol p {
        font-size: .875rem;
    }
    .ourfish-section-title {
        font-size: 1rem;
    }
    .ourfish-section h2 {
        text-align: center;
    }
    .ourfish-section h2::after {
        left: 50%;
        transform: translateX(-50%);
    }
    .ourfish-section-header {
        align-items: center;
    }
    .ourfish-link {
        width: 100%;
    }
}
