.video-section {

  background-color: #FAFAF5;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

}

.video-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px 50px;
  position: relative;
}

.video-section-tilte {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #173050;
  text-align: center;
}

.video-title {
  font-size: 40px;
  font-family: 'pulpo-rust-100', serif;
  margin-bottom: 0;
  padding: 0 25px;
}

.video-subtitle {
  font-size: 18px;
  font-weight: 450;
  margin: 10px 0;
}

.video-section iframe {
  width: 100%;
  max-width: 712px;
  height: 400px;
  border: none;
  margin-top: 140px;
}

/* responsive design */
@media (max-width: 1023px) {
  .video-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .video-title {
    font-size: 28px;
  }

  .video-section iframe {
    height: auto;
    aspect-ratio: 16 / 9;
    /* keep video aspect ratio */
  }
}