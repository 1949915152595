.signup-container {
  text-align: center;
  background-color: #FAFAF5;
}
.signup-wrapper {
  max-width: 1350px;
  padding: 0 25px;
  margin: auto;
  overflow: hidden;
}

.signup-title {
  color: #173050;
  font-size: 40px;
  font-family: 'pulpo-rust-100', serif;
}

.input-container {
  margin: 0 0 20px;
  display: flex;
  flex-direction: row; 
  justify-content: center; 
  align-items: center; 
}

.input-container input,
.PhoneInput {
  margin: 5px 10px;
  padding: 10px;
  border: 1px solid #ddd;
  transition: .3s ease;
  flex: 1; /* make every input flexible */
}

.input-container input:hover {
  border-color: #173050;
}

.input-container input:focus {
  outline: none;
  border-color: #173050;
}

.signup-button {
  padding: 10px 20px;
  background-color: #AC2825;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: .3s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}

.signup-button:hover {
  background-color: #173050;
}

.signup-disclaimer {
  font-size: 11px;
  margin: 20px auto;
  color: #565656;
  text-align: center;
}


/* adjust input style for phone number */
.PhoneInput {
  padding: 0;
  margin: 0;
  border: none;
}

/* responsive design */
@media (max-width: 1023px) {
  .signup-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .signup-title {
    font-size: 28px;
  }
}

@media (max-width: 576px) {
  .signup-container {
    max-width: 100%;
  }

  .input-container {
    flex-direction: column; /* vertical layout in smaller screen */
  }

  .input-container input,
  .PhoneInput {
    width: 90%;
    margin: 5px auto;
  }
}
