.price-list-banner {
  height: 485px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.price-list-banner-wrapper {
  display: flex;
  align-items: center;
  max-width: 1350px;
  height: 100%;
  padding: 0 25px;
  margin: auto;
}

.price-list-banner-content {
  width: 455px;
  background-color: rgba(255, 255, 255, .85); 
  padding: 36px;
  backdrop-filter: saturate(180%) blur(20px);
}

.price-list-banner-button-content {
  margin-top: 20px; 
}

.price-list-banner-text1, .price-list-banner-text2, .price-list-banner-text3 {
  color: #173050;
  margin: 0 0 15px;
}

.price-list-banner-text1 {
  font-family: 'pulpo-rust-100', serif;
  font-size: 28px;
  color: #AC2825;
}

.price-list-banner-text2 {
  font-size: 32px;
  font-weight: 500;
}

.price-list-banner-text3 {
  font-size: 21px;
}

.price-list-banner-button {
  display: inline-block;
  background-color: #e9a343; 
  color: white;
  border: none;
  padding: 10px 20px;
  transition: background 0.3s ease;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 500;
}

.price-list-banner-button:hover {
  background-color: #173050; 
}

@media (max-width: 767px) {
  .price-list-banner {
    height: 400px; 
  }
  .price-list-banner-wrapper {
    justify-content: center;
  }
  .price-list-banner-content {
    text-align: center;
  }
  .price-list-banner-text2 {
    font-size: 24px;
  }

}

@media (max-width: 576px) {
  .price-list-banner-content {
    width: auto;
  }
  .price-list-banner-text1 {
    font-size: 24px;
  }
  .price-list-banner-text2 {
    font-size: 20px;
  }
  .price-list-banner-text3 {
    font-size: 18px;
  } 
}
