/* Basic style */
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  background: rgba(23, 48, 80, 1);
  transition: .3s ease;
}

/* .navbar.scrolled {
  background: rgba(23, 48, 80, .9);
  backdrop-filter: saturate(180%) blur(20px);
} */

.navbar-top-wrapper {
  max-width: 1400px;
  margin: auto;
}

.search-bar {
  position: relative;
  display: flex;
  width: 60%;
  margin-left: auto;
}

.search-bar .search-button,
.search-bar-mobile .search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9a343;
  color: #FFF;
  width: 4rem;
}

.search-bar .search-button:hover,
.search-bar-mobile .search-button:hover {
  font-size: 1.125rem;
}

.search-bar .search-button:active,
.search-bar-mobile .search-button:active {
  font-size: 1rem;
}

.search-bar-wrapper {
  position: relative;
  padding: 15px 25px;
  max-width: 1350px;
  margin: auto;
}

.search-bar-input,
.search-bar-mobile-input {
  position: relative;
  width: 100%;
}

.search-bar .search-bar-input button {
  position: absolute;
  right: 35px;
  top: 3.5px;
  font-size: .875rem;
  color: #173050;
  transition: .3s ease;
  border: 1px solid #173050;
  height: 28px;
  line-height: 28px;
}

.search-bar .search-bar-input button.active {
  background: #173050;
  color: #FFF;
}

.search-bar input,
.search-bar-mobile input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  background: none;
  outline: none;
  padding: 6px 15px;
  height: 47px;
  color: #173050;
  background-color: #FFF;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
}
.search-bar input::placeholder {
  color: #173050 !important;
  font-weight: 450;
}

.search-bar input:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
}

.search-bar input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.search-bar button {
  position: absolute;
  top: 1rem;
  right: 2rem;
  background: none;
  border: none;
  color: #173050;
  padding: 0 5px;
  cursor: pointer;
  font-size: 16px;
  height: 35px;
  line-height: 35px;
  transition: .3s ease;
}

.search-bar button:hover {
  opacity: 0.7;
  color: #173050;
}


/* top navbar style */
.navbar-top {
  display: flex;
  align-items: center;
  padding: 15px 25px 10px;
  color: white;
  transition: .5s ease;
}

.navbar.scrolled .navbar-top {
  padding: 10px 25px;
}

.navbar-logo {
  margin: 0 20px 0 10px;
}

.navbar-logo img {
  height: 43px;
  transition: all .3s ease;
}

.navbar-buttons {
  display: flex;
  margin-left: 2rem;
}

.navbar.scrolled .navbar-top img {
  height: 35px;
}

.navbar-buttons button,
.navbar-buttons a {
  background: none;
  border: none;
  color: #fff;
  padding: 8px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s;
  font-size: 14px;
  position: relative;
}

.navbar-buttons a.signin-button img {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}

.navbar-buttons button span,
.navbar-buttons a span {
  margin-left: 5px;
}

.navbar-buttons button:hover,
.navbar-buttons a:hover {
  color: rgba(255, 255, 255, 0.6);
}

.cart-count {
  background-color: #AC2825;
  color: white;
  text-align: center;
  min-width: 10px;
  min-height: 10px;
  line-height: 10px;
  border-radius: 15px;
  padding: 5px;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  left: 20px;
}

.navbar-bottom {
  padding: 0 25px;
  margin-bottom: 5px;
  z-index: 100;
}

.navbar-bottom-small {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background: #173050;
  margin: 0;
  padding-left: 40px;
  z-index: 100;
  list-style: none;
  animation: slide-in .3s ease;
  box-sizing: border-box;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.navbar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  animation: fade-in .3s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.navbar-bottom-small li {
  margin: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-bottom-small li a {
  display: inline-block;
  padding: 20px 0;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.navbar-buttons .menu-button {
  display: none;
}


/* bottom navbar style */
.navbar-bottom-normal {
  margin: 0 8px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 0;
  margin-block-end: 0;
  transition: .5s ease;
}

.navbar-bottom-normal li {
  line-height: 35px;
  display: block;
  transition: line-height 0.3s ease;
}

.navbar.scrolled .navbar-bottom-normal li {
  line-height: 24px;
}

.navbar-bottom-normal a {
  display: block;
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 14px;
  letter-spacing: 1px;
}

.navbar.scrolled .navbar-bottom-normal a {
  font-size: 14px;
}

.navbar-bottom-normal a:hover {
  color: rgba(255, 255, 255, 0.6);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #173050;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu when hovering over the link */
.dropdown:hover .dropdown-content {
  display: block;
}

.search-bar-control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.search-bar-control.close {
  cursor: pointer;
  color: #173050;
}

.search-results.hide {
  display: none;
}

.search-results {
  position: relative;
  margin: 0 0 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, .1);
  max-height: 75vh;
  overflow: auto;
}

.search-results-wrapper {
  max-width: 1350px;
  padding: 0 25px;
  margin: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.result-list {
  display: flex;
  text-decoration: none;
  padding: .875rem;
  overflow: hidden;
  background-color: rgba(255, 255, 255, .05);
  color: #fff;
  gap: 1rem;
  transition: all .3s ease;
}

.result-list:hover {
  background-color: rgba(255, 255, 255, .1);
}

.result-list h3 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: normal;
  text-align: left;
  font-family: 'pulpo-rust-100', serif;
}

.result-image {
  flex: 0 0 64px;
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.result-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.result-info .price,
.result-info .price-uom,
.result-info .regular-price,
.result-info .regular-price .price-uom {
  color: #FFF;
}

.store-locator-button {
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: .875rem;
  text-align: left;
}

.store-locator-icon {
  margin-right: 10px;
  font-size: 1.125rem;
  cursor: pointer;
}

.store-locator-selected {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.store-locator-timing {
  opacity: .8;
  margin-top: 2px
}

.navbar-store-locator-mobile {
  display: none;
  background: #1d3b62;
}

.navbar-store-locator-mobile-wrapper {
  max-width: 1350px;
  margin: auto;
  padding: 5px 25px;

}

.search-bar-mobile {
  display: none;
  padding: 10px 25px 10px;
  position: relative;
  transition: .5s ease;
}

.search-bar-mobile input {
  height: 36px;
  width: 100%;
}
.search-bar-mobile .search-button {
  width: 3.5rem;
}
.search-bar-mobile-input {
  width: 100%;
}

.navbar-buttons .search-button-mobile {
  display: none;
}

/* responsive design */
@media (max-width: 1280px) {
  .navbar-bottom-normal {
    display: none;
  }

  .navbar-buttons .menu-button {
    display: block;
  }

  .navbar-bottom-small {
    display: block;
  }
  .navbar-store-locator {
    display: none;
  }

  .navbar-store-locator-mobile {
    display: block;
  }

  .store-locator-button {
    width: 100%;
  }
  .store-locator-timing {
    margin-left: auto;
  }


}
@media (max-width: 1023px) {
  .navbar-bottom-normal {
    display: none;
  }

  .navbar-buttons .menu-button {
    display: block;
  }

  .navbar-bottom-small {
    display: block;
  }

  .search-results-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .navbar-buttons {
    margin-left: 1rem;
  }
}

@media (max-width: 768px) {
  .search-bar {
    display: none;
  }
  .navbar-buttons {
    margin-left: auto;
  }
  .search-bar-mobile {
    display: flex;
  }
  .navbar-top img {
    height: 35px;
  }
  .navbar-top {
    padding: 10px 25px;
  }
  .search-results-wrapper {
    grid-template-columns: 1fr;
  }
  .navbar-buttons .search-button-mobile {
    display: block;
  }
}

@media (max-width: 576px) {
  .navbar-logo img {
    height: 35px;
  }

  .navbar-bottom-small {
    width: 100vw;
  }

  .result-list {
    padding: .875rem;
  }

  .result-list h3 {
    font-size: 1rem;
  }

  .result-info .price,
  .result-info .price-uom {
    font-size: 1rem;
  }

  .result-info .regular-price,
  .result-info .regular-price .price-uom {
    font-size: .875rem;
  }
}